import React from "react";
import DashboardHeader from "../components/Other/DashboardHeader.jsx";
import { useOutletContext } from "react-router-dom";
import LogoImage from '../../src/images/favicon_exter.png';

function Dashboard() {
  const avatar = LogoImage;

  const [sidebarToggle] = useOutletContext();

  return (
    <>
      <main className="h-full">
        {/* Welcome Header */}
        <DashboardHeader
          toggle={sidebarToggle}
          avatar={avatar}
          user={{ name: "Admin" }}
        />
      </main>
    </>
  );
}

export default Dashboard;
