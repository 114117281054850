import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Table from "./pages/Table";
import AuthLayout from "./components/Layout/AuthLayout";
import GuestLayout from "./components/Layout/GuestLayout";
import Login from "./pages/auth/Login";
import Blank from "./pages/Blank";
import NotFound from "./pages/NotFound";
import Form from "./pages/Form";
import RegisterIndex from "./pages/auth/Register";

function App() {
  return (
    <Routes>
      <Route path="/p" element={<AuthLayout />}>
        <Route path="/p/" element={<Dashboard />}></Route>
        <Route path="/p/table" element={<Table />}></Route>
        <Route path="/p/blank" element={<Blank />}></Route>
        <Route path="/p/404" element={<NotFound />}></Route>
        <Route path="/p/form" element={<Form />}></Route>
        <Route path="/p/profile" element={<Blank />}></Route>
      </Route>
      <Route path="/" element={<GuestLayout />}>
        <Route path="/" element={<Login />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<RegisterIndex />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
